/* Responsive Variables */
$max-height-trigger: 920px;

// Colors
$vermilion: #ff301b;
$ash: #2c2a28;
$pewterdeprecated: #717171;
$clay: #a9a19a;
$sand: #c0b7af;
$blush: #faf3f1;
$white: #ffffff;


$ash-a: rgba(44,42,40,0.50);

$rich-taupe: #968774;
$mud: #999291;
$oxide: #100f0f;
$moss: #37544e;
$chocolate: #464242;
$kaolin: #F1EEEE;
$porcelain: #F8F5F5;
$coral: #B14949;
$patina: #5B5756;
$marble: #EEEDEB;
$cinnabar: #dd3747;
$limestone: #D3CDCC;
$moss: #37744C;

/* New */
$lapis: #2349b6;
$pine: #419b6c;
$grey-grade-5: #7f7f7f;
$house-black: #111;
$bergamot: #ffd300; /* RGB 255, 211, 0 */
$bergamot-peel: #ffc600;
$orange: #ffba00;

$porcelain: #f4f4f4;
$silver: #aaa;
$trueBlack: #000;
$houseBlack: #111;
$houseBlue: #00aeef;
$link: #1e7ac8;
$tarnish: #565553;
$tin: #333;
$steal: #999;

$jade: #60caad;
$stone: #7b7976;
$newsprint: #a2a19e;
$sand: #e2e0dc;
$muslin: #f6f4f0;
$kings-white: #f4f4f4;


$tangier: #4258d9;
$water : #4e67f7;
$indigo : #30409b;
$cloud : #4c88d9;
$grass: #15bd8c;
$brick: #d43030;
$sunflower: #f7d361;
$lichen: #dbfebf;
$malachite: #00a67c;
$carbon: #27282a;
$dust: #9b9b9b;
$pebble: #e2e8f1;
$hydrangea: #ecf3fc;
$porcelain: #f4f4f4;
$muslin: #f7f3f1;
$input: #d6d8dd;
$taupe : #a29f9e;
$dark-line: rgba(0,0,0,0.3);
$line: rgba(0,0,0,0.1);
$line-light: rgba(0,0,0,0.05);

// Journal Colors
$kaolin: #fafafa;
$porcelain: #f4f4f4;
$silver: #aaa;
$trueBlack: #000;
$houseBlack: #111;
$houseBlue: #00aeef;
$link: #1e7ac8;
$pewter: #444;
$tin: #333;
$steal: #999;

@mixin base-layout() {
    @media screen and (min-width: 1661px) {
        width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 1660px) and (min-width: 480px) {
        margin-left: 8%;
        margin-right: 8%;
    }
    @media screen and (max-width: 480px) {
        margin-left: 12%;
        margin-right: 12%;
    }
}
@mixin running($type: medium) {
    font-family: Empirica, sans-serif;
    color: #363533;
    @if $type == maxi {
        font-family: Empirica, sans-serif;
        font-size: 95px;
        line-height: 100px;
        @media screen and (max-width: 480px) {
            font-size: 56px;
            line-height: 64px;
        }
    }
    @if $type == xlarge {
        font-family: Empirica, sans-serif;
        color: #000;
        font-size: 64px;
        line-height: 64px;
        @media screen and (max-width: 480px) {
            font-size: 36px;
            line-height: 42px;
        }
    }
    @if $type == large {
        @include chronicle-display-condensed-black();
        font-size: 36px;
        line-height: 44px;
        color: #363533;
        /* Refactor
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        */
        @media screen and (max-width: 480px) {
            font-size: 24px;
            line-height: 40px;
        }
    }
    @if $type == medium {
        font-size: 24px;
        line-height: 38px;
        font-weight: 400;
        @media screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 34px;
        }
    }
    @if $type == small {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        @media screen and (max-width: 480px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    @if $type == note {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        @media screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    @if $type == small-caps {
        @include uppercase();
        font-size: 16px;
        color: #acaaa4;
        letter-spacing: 2px;
    }
    @if $type == small-caps-universal {
        @include uppercase();
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 4px;
    }
}





@mixin responsive-image() {
    width: 100%;
    max-width: 100%;
    display: block;
}


@mixin pointer-events() {
    pointer-events: none;
}
@mixin pseudo() {
    @include pointer-events();
    display: block;
    content: "";
}
@mixin travel-title($type: large) {
    @include chronicle-display-condensed-black();
    font-weight: 600;
    @if $type == xlarge {
        font-size: 56px;
        line-height: 60px;
    }
    @if $type == large {
        font-size: 36px;
        line-height: 44px;
    }
    @if $type == medium {
        font-size: 24px;
        line-height: 28px;
    }
    @if $type == small {
        font-size: 20px;
        line-height: 26px;
    }
    @if $type == xsmall {
        font-size: 18px;
        line-height: 24px;
    }
    @if $type == small-caps {
        @include small-caps();
        margin-bottom: 10px;
    }
}
@mixin travel-description($type: medium) {
    color: $tin;
    @if $type == medium {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
    }
    @if $type == small {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
    }
    @if $type == note {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }
    /*
    @else if $type == italic {
    font-style: italic;
    }
    */
}
@mixin travel-button($type: standard) {
    @include border-radius(5px);
    @include transition($property: all, $speed: 400ms);
    font-family: "Norms", Helvetica, sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    background: $tangier;
    padding: 12px 17px 10px;
    white-space: nowrap;
    @if $type == plain {
        @include border-radius(0);
        border: 0;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        text-align: inherit;
        padding: 0;
        background: none;
        color: $tangier;
        &:hover {
            color: $water;
        }
        &:active {
            color: $indigo;
        }
    }
    @if $type == ecommerce {
        background: $grass;
        color: #fff;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $water;
            border-color: $water;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $indigo;
            border-color: $indigo;
        }
    }
    @if $type == standard {
        background: $tangier;
        color: #fff;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $water;
            border-color: $water;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $indigo;
            border-color: $indigo;
        }
    }
    @if $type == minor {
        @include description(small);
        color: $tangier;
        position: relative;
        display: inline-block;
        text-align: left;
        padding: 0 14px 0 0;
        white-space: nowrap;
        background: none;
        span {
            @include pseudo();
            position: absolute;
            top: 5px;
            right: 0;
            bottom: 0;
            width: 7px;
            height: 11px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: url("/assets/images/shared/arrow_e_4258d9.svg");
            @include background-size(7px 11px);
        }
    }
    @if $type == minor-left {
        @include description(small);
        color: $tangier;
        position: relative;
        display: inline-block;
        text-align: left;
        padding: 0 0 0 14px;
        white-space: nowrap;
        background: none;
        span {
            @include pseudo();
            position: absolute;
            top: 5px;
            left: 0;
            bottom: 0;
            width: 7px;
            height: 11px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: url("/assets/images/shared/arrow_w_4258d9.svg");
            @include background-size(7px 11px);
        }
    }
    @if $type == ghost {
        background: transparent;
        color: $tangier;
        border: 2px solid $tangier;
        padding: 10px 15px 8px;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $water;
            border-color: $water;
            color: #fff;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $indigo;
            border-color: $indigo;
            color: #fff;
        }
    }
    @if $type == ghost-arrow {
        position: relative;
        background: transparent;
        color: $tangier;
        border: 2px solid $tangier;
        padding: 10px 40px 8px 15px;
        &:after {
            @include pseudo();
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            margin: auto;
            width: 14px;
            height: 14px;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-image: url("/assets/images/shared/arrow_ne_4258d9.svg");
            @include background-size(14px);
        }
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $water;
            border-color: $water;
            color: #fff;
            &:after {
                background-image: url("/assets/images/shared/arrow_ne_ffffff.svg");
            }
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $indigo;
            border-color: $indigo;
            color: #fff;
        }
    }
    @if $type == pill {
        @include border-radius(20px);
        background: #fff;
        color: $tangier;
        padding: 5px 15px 4px;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $water;
            color: #fff;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $indigo;
            color: #fff;
        }
    }
}
@mixin travel-small-caps($type: standard) {
    @include uppercase();
    font-weight: 600;
    font-size: 12px;
    @if $type == standard {
        color: $silver;
    }
}








@mixin chronicle-display-condensed-black($type: normal) {
    @if $type == normal {
        font-family: "Chronicle Cond A", "Chronicle Cond B";
        font-style: normal;
        font-weight: 800;
    }
    @if $type == italic {
        font-family: "Chronicle Cond A", "Chronicle Cond B";
        font-style: italic;
        font-weight: 800;
    }
}

@mixin japanese {
    font-family:"Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin benton-sans-thin {
    font-family: "Benton Sans Thin", "Avenir Thin", Avenir, sans-serif;
    font-weight: 100;
}
@mixin benton-sans-extra-light {
    font-family: "Benton Sans Ext Light", "Avenir Light", Avenir, sans-serif;
    font-weight: 200;
}
@mixin benton-sans-book {
    font-family: "Benton Sans Book", "Avenir Book", Avenir, sans-serif;
    font-weight: 200;
}
@mixin benton-sans-medium {
    font-family: "Benton Sans Medium", "Avenir Light", Avenir, sans-serif;
}
@mixin absolute-suite($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}
@mixin flex() {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
}
@mixin opacity($opacity) {
    opacity: $opacity;
}
@mixin border-radius($radius) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}
@mixin constrained-layout() {
    margin-right: 4%;
    margin-left: 4%;
    @media screen and (min-width: 2400px) {
        max-width: 2400px;
        margin-right: auto;
        margin-left: auto;
    }
}
@mixin table-reset {
    border-collapse: collapse;
    border: 0;
    width: 100%;
    @media screen and (max-width: 640px) {
        td {
            display: block;
            text-align: center;
            padding: 8%;
            width: 100% !important;
            background: $sand;
            border: 0;
        }
    }
}
@mixin box-shadow(  $offset-x,
                    $offset-y,
                    $blur-radius,
                    $spread-radius,
                    $color,
                    $inset: null
                    ) {
    box-shadow: $inset $offset-x $offset-y $blur-radius $spread-radius $color;
    -moz-box-shadow: $inset $offset-x $offset-y $blur-radius $spread-radius $color;
    -webkit-box-shadow: $inset $offset-x $offset-y $blur-radius $spread-radius $color;
}
@mixin uppercase($letterspacing: 3px) {
    text-transform: uppercase;
    letter-spacing: $letterspacing;
    /* This will enforce even spacing and prevent ligatures */
    font-variant-ligatures: no-common-ligatures;
}
@mixin inline-block {
    display: inline-block;
    vertical-align: top;
}
@mixin transition($property, $speed, $easing: cubic-bezier(0.175, 0.885, 0.32, 1.275)) {
    -webkit-transition: $property $speed $easing;
    -moz-transition: $property $speed $easing;
    transition: $property $speed $easing;
}
@mixin transition-expo($property, $speed, $easing: cubic-bezier(0.16, 1, 0.3, 1)) {
    -webkit-transition: $property $speed $easing;
    -moz-transition: $property $speed $easing;
    transition: $property $speed $easing;
}
@mixin ui-small-caps($color: $pewter) {
    @include uppercase();
    font-family: "Empirica", serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color;
}
@mixin title($type: large, $color: $ash) {
    color: $color;
    font-family: "Empirica", serif;
    font-weight: 400;
    margin: 0;
    color: $oxide;
    @if $type == maxi {
        font-size: 112px;
        line-height: 120%;
        font-weight: 200;
    }
    @if $type == xlarge {
        font-size: 96px;
        line-height: 96px;
        font-weight: 200;
        @media screen and (max-height: $max-height-trigger) {
            font-size: 64px;
            line-height: 120%;
        }
        @media screen and (max-width: 880px) {
            font-size: 56px;
            line-height: 64px;
        }
        @media screen and (max-width: 640px) {
            font-size: 40px;
            line-height: 48px;
        }
    }
    @if $type == large {
        font-size: 48px;
        line-height: 144%;
    }
    @if $type == medium {
        font-size: 24px;
        line-height: 144%;
    }
    @if $type == small {
        font-size: 18px;
        line-height: 144%;
    }
}
@mixin subtitle($type: normal, $color: #000) {
    font-family: "garamond-premier-pro", serif;
    font-size: 28px;
    line-height: 35px;
    font-weight: 400;
    margin: 0;
    color: $color;
    @if $type == normal {
        font-style: normal;
    }
    @else if $type == italic {
        font-style: italic;
    }
}
@mixin description($type: normal, $color: $pewter) {
    font-family: "Empirica", serif;
    font-size: 22px;
    line-height: 25px;
    font-weight: 400;
    color: $color;
    @if $type == regular {
        font-style: normal;
    }
    @else if $type == italic {
        font-style: italic;
    }
    @if $type == large {
        font-size: 48px;
        line-height: 136%;
        font-weight: 300;
        @media screen and (max-height: $max-height-trigger) {
            font-size: 32px;
        }
    }
    @if $type == small {
        font-size: 18px;
        line-height: 136%;
    }
    @if $type == xsmall {
        font-size: 16px;
        line-height: 144%;
    }
}
@mixin title-secondary() {
    font-size: 48px;
    font-family: "Empirica", serif;
    font-weight: 100;
    color: #444;
    line-height: 52px;
}
@mixin caption() {
    font-size: 16px;
    font-family: "Avenir Medium", Avenir, sans-serif;
    color: $steal;
    line-height: 30px;
}
@mixin running-text() {
    @include benton-sans-book();
    color: $stone;
    font-size: 16px;
    line-height: 28px;
    margin: 10px 0;
    &:first-of-type {
        margin: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    .jp & {
        @include japanese();
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
    }
}
@mixin note-text() {
    @include benton-sans-medium();
    color: $newsprint;
    font-size: 14px;
    line-height: 20px;
    .jp & {
        @include japanese();
    }
}
@mixin button($type: null) {
    display: block;
    text-align: center;
    /* All-caps small button */
    @if $type == majascule {
        @include ui-small-caps();
        color: $jade;
    }
    @else if $type == minor {
        color: green;
    }
    /* Standard button */
    @else {
        @include description();
        @include border-radius(33px);
        @include inline-block();
        color: $jade;
        position: relative;
        border: 1px solid $jade;
        padding: 15px 30px 14px;
    }
}
@mixin input() {
    @include border-radius(33px);
    @include description();
    padding: 13px 30px 12px;
    margin: 0;
    border: 2px solid $sand;
    outline: 0;
    color: $stone;
}
@mixin input-small() {
    @include benton-sans-medium();
    @include border-radius(33px);
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 17px;
    border: 2px solid $sand;
    outline: 0;
    color: $tarnish;
    width: 100%;
    -webkit-appearance: none;
}
@mixin background-size($value: cover) {
    -webkit-background-size: $value;
    -moz-background-size: $value;
    background-size: $value;
}
%linkFeedback {
    cursor: pointer;
}
@mixin clear-fix() {
    content: "";
    display: block;
    clear: both;
}
@mixin external-link {
    @include border-radius(24px);
    white-space: nowrap;
    font-family: Empirica, sans-serif;
    display: inline-block;
    padding: 5px 16px 8px;
    background: $moss;
    color: #fff;
    border: 0;
    &:hover {
        background: $coral;
        border: 0;
    }
}



@mixin page-width($type) {
    @if $type == journal {
        width: 880px;
        @media screen and (max-width: 960px) {
            width: 100%;
        }
    }
    @if $type == monograph {
        width: 1440px;
        @media screen and (max-width: 960px) {
            width: 100%;
        }
    }
    @if $type == portfolio {
        width: auto;
    }
}
@mixin image-breadths() {
    &.breadth-equal-to-text {
        @include page-width($type: journal);
    }
    &.breadth-preserve-size {
        img {
            width: auto !important;
            margin: 0 auto;
            display: block;
        }
    }
    &.breadth-small {
        max-width: 960px;
    }
    &.breadth-medium {
        max-width: 1200px;
    }
    &.breadth-large {
        max-width: 1600px;
    }
    &.breadth-extra-large {
        max-width: 2400px;
    }
    &.breadth-expansive {
        width: 100%;
    }
}