@import "library";

// Absolute suite
@mixin absoluteSuite($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}
@mixin opacity($opacity) {
    opacity: $opacity;
}
@mixin borderRadius($radius) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}
@mixin inlineBlock {
    display: inline-block;
    vertical-align: top;
}
@mixin heading($type: large) {
    font-family: Empirica, sans-serif;
    font-weight: 200;
    color: $oxide;
    @if $type == large {
        font-size: 72px;
        line-height: 78px;
        @media screen and (max-width: 960px) {
            font-size: 48px;
            line-height: 56px;
        }
    }
    @if $type == medium {
        font-size: 36px;
        line-height: 64px;
        font-style: italic;
    }
}
@mixin quote() {
    @include heading();
    position: relative;
    quotes: "“" "”" "‘" "’";
    display: block;
    font-weight: 300;
    color: $oxide;
    i {
        font-style: italic;
        // Because quotes are set in italics, italics must be set in Roman
        font-style: normal;
    }
    &:before {
        @include pseudo();
        width: 28px;
        height: 24px;
        line-height: 32px;
        font-size: 88px;
        content: "“";
        font-style: normal;
        font-weight: 700;
        color: rgba(0,0,0,0.3);
    }
    &:after {
        content: "”";
        font-style: normal;
        font-weight: 700;
        padding-left: 8px; /* Not ideal, but when an inner quote is present, it collides with this one. */
        color: rgba(0,0,0,0.3);
    }
    @media screen and (max-width: 800px) {
        font-size: 36px;
        line-height: 46px;
        >q {
            font-weight: 400;
            &:before {
                top: 10px;
                left: -20px;
            }
        }
    }
}
@mixin description($type: medium) {
    font-family: "Fira", "Avenir Medium", Avenir, sans-serif;
    color: $ash;
    @if $type == large {
        font-weight: 300;
        font-size: 36px;
        line-height: 64px;
    }
    @if $type == medium {
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
    }
}
%backgroundCover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}
%linkFeedback {
    cursor: pointer;
}
%clear-fix {
    content: "";
    display: block;
    clear: both;
}
body {
    &.journal,
    &.themes {
        background-color: $limestone;
        padding-top: 80px;
    }
}

h1,h2,h3,h4,h5,h6,p,figure,ul,ol,blockquote,q {
    margin: 0;
    font-weight: normal;
}
h1,h2,h3,h4,h5,h6 {
    font-family: Empirica, sans-serif;
}
ul {
    list-style: none;
    padding: 0;
}
a {
    text-decoration: none;
    color: $oxide;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    &:hover {
        opacity: 1;
        color: #000;
        border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    &:active {
        opacity: 1;
        color: #000;
        border-bottom: 1px solid rgba(0,0,0,0.8);
    }
}

/* Temp Launch CSS */
.journal-hero-title {
    text-align: center;
    font-size: 120px;
    font-weight: 100;
    color: rgba(0,0,0,0.4);
    margin: 2% 0 4% 0;
}

.content.journal {
    @include description();
    @include flex();
    flex-direction: column;
    >article {
        @include flex();
        flex-direction: column;
        align-items: center;
        text-align: left;
        margin: 4% auto;
        >.post-content {
            /* Specialty Styles */
            &.historic-type {
                >section {
                    >.text {
                        blockquote {
                            font-family: "Fell Type A", "Fell Type B";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 36px;
                        }
                    }
                }
            }
            >section {
                >.graphics {
                    &.grid-distribute-evenly {
                        @include image-breadths();
                        display: grid;
                        grid-gap: 1px;
                        grid-auto-flow: column;
                    }
                }
                >.recipe {
                    @include page-width($type: journal);
                    border-top: 1px solid $line;
                    border-bottom: 1px solid $line;
                    padding: 24px 0;
                    margin: 48px 0;
                    >h3 {
                        @include running(small-caps-universal);
                        color: rgba(0,0,0,0.7);
                        padding-bottom: 20px;
                        text-align: center;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        td {
                            padding-right: 2%;
                            font-weight: 700;
                            width: 30%;
                            padding: 5px 0;
                        }
                        td+td {
                            width: 70%;
                            padding-right: 0;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
        >section {
            >.table {
                @include page-width($type: journal);
                border-top: 1px solid $line;
                border-bottom: 1px solid $line;
                padding-bottom: 20px;
                >.title {
                    @include ui-small-caps();
                    margin: 0;
                    padding: 20px 0;
                    font-weight: 400;
                    text-align: center;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    td {
                        padding: 5px 0;
                    }
                }
                &.twoColumn {
                    td {
                        width: 27%;
                        padding-right: 2%;
                        font-weight: bold;
                    }
                    td+td {
                        width: 71%;
                        padding-right: 0;
                        font-weight: normal;
                    }
                }
            }
        }
        .date {
            @include page-width($type: journal);
            @include running(small-caps-universal);
            color: rgba(0,0,0,0.7);
            padding-bottom: 20px;
            a {
                color: $coral;
                border: 0;
                &:hover {
                    color: $cinnabar;
                }
            }
        }
        .title {
            @include page-width($type: journal);
            @include heading();
            margin: 0 0 25px;
            >h1 {
                @include heading();
            }
        }
        .text {
            @include page-width($type: journal);
            margin: 0 auto;
            >p {
                margin: 24px 0;
                code {
                    display: inline;
                    padding: 0;
                }
                &:first-of-type {
                    margin-top: 48px;
                }
                &:last-of-type {
                    margin-bottom: 48px;
                }
            }
            >p + p {
                text-indent: 30px;
            }
        }
        .source {
            @include page-width($type: journal);
            margin: 0 auto;
            color: rgba(0,0,0,0.7);
            margin-top: 4%;
            .table {
                display: table;
                border-collapse:collapse;
                width: 100%;
                >.row {
                    display: table-row;
                    >.cell {
                        display: table-cell;
                        padding: 16px 0 17px;
                        &.label {
                            @include running(small-caps-universal);
                            color: rgba(0,0,0,0.4);
                            width: 160px;
                        }
                        a {
                            color: $coral;
                            border: 0;
                            &:hover {
                                color: $cinnabar;
                            }
                        }
                    }
                }
            }
        }
        .text + .source {
            margin-top: 20px;
        }
        blockquote {
            @include page-width($type: journal);
            @include quote();
            margin: 40px 0 60px;
            p {
                display: inline;
                font-weight: 300;
                color: $oxide;
            }
        }
        .quip {
            @include page-width($type: journal);
            color: $oxide;
            margin-left: -60px;
            padding-bottom: 3px;
        }
        ol {
            @include page-width($type: journal);
            margin: 24px auto;
            padding: 0;
            counter-reset: ordinals;
            border-top: 1px solid $line;
            li {
                position: relative;
                border-bottom: 1px solid $line;
                list-style: none;
                padding: 9px 0 10px 65px;
                &:before {
                    color: rgba(0,0,0,0.3);
                    position: absolute;
                    top: auto;
                    left: 0;
                    counter-increment: ordinals;
                    content: "0" counter(ordinals);
                }
                &:nth-of-type(1n+10) {
                    &:before {
                        content: counter(ordinals);
                    }
                }
                .inline-header {
                    font-weight: 600;
                    padding-right: 10px;
                    font-style: normal;
                }
            }
        }
        >.endnote {
            @include page-width($type: journal);
        }
        i {
            font-style: italic;
            font-weight: inherit;
        }
        cite {
            font-style: normal;
        }
        abbr {
            cursor: help;
            &.sc {
                @include ui-small-caps();
                font-size: .9em;
            }
        }
        h2 {
            @include page-width($type: journal);
            @include heading($type: medium);
            margin: 30px auto 10px;
        }
        code,
        pre {
            display: block;
            background: rgba(0,0,0,0.05);
            color: $moss;
            font-size: 16px;
            white-space: pre-wrap;
            word-wrap: break-word; 
            padding: 4%;
        }
        .graphic + .text {
            margin-top: 40px;
        }
        .graphic + .source > .table {
            border-top: 0;
        }
        .graphic {
            width: 100%;
            margin: 0 auto 1px;
            &.featured {
                margin: 0 0 1px;
                img {
                    display: block;
                }
            }
            &.preserveSize {
                img {
                    width: auto !important;
                    margin: 0 auto;
                    display: block;
                }
            }
            &.chart {
                background: #fff;
                padding: 4%;
            }
            &.px1200 {
                width: 1200px;
            }
            &.px960 {
                width: 960px;
            }
            >img {
                width: 100%;
                max-width: 100%;
            }
            >a {
                color: $moss;
                >img {
                    width: 100%;
                    max-width: 100%;
                    display: block;
                    &:hover {
                        opacity: 0.5;
                    }
                    &:active {
                        opacity: 0.9;
                    }
                }
            }
            @include image-breadths();
        }
        .array {
            &.themes {
            @include page-width($type: journal);
                >.item {
                    &.theme {
                        @include running(small-caps-universal);
                        @include border-radius(8px);
                        display: inline-block;
                        background: rgba(0,0,0,0.07);
                        border: 0;
                        padding: 8px 8px 8px 12px; /* Account for letter-spacing */
                        &:hover {
                            background: rgba(0,0,0,0.1);
                        }
                    }
                }
            }
        }
        .feature-image {
            display: grid;
            grid-gap: 1px;
            grid-auto-flow: column; /* See: https://css-tricks.com/flexbox-like-just-put-elements-in-a-row-with-css-grid/ */
            /* These are set in the Craft field type "featuredImageBreadth" */
            margin-bottom: 1px;
            >.graphic {
                margin: 0;
            }
            &.breadth-equal-to-text {
                @include page-width($type: journal);
            }
            &.breadth-preserve-size {
                img {
                    width: auto !important;
                    margin: 0 auto;
                    display: block;
                }
            }
            &.breadth-small {
                max-width: 960px;
            }
            &.breadth-medium {
                max-width: 1200px;
            }
            &.breadth-large {
                max-width: 1600px;
            }
            &.breadth-extra-large {
                max-width: 2400px;
            }
            &.breadth-expansive {
                width: 100%;
            }
        }
        .in-text-grid {
            margin: 48px 0;
            display: grid;
            grid-gap: 1px;
            grid-template-columns: repeat(2, 1fr);
            >.graphic {
                margin: 0;
                &.full-width {
                    grid-column-start: 1;
                    grid-column-end: -1;
                }
                >img {
                    display: block;
                }
            }
        }
        .out-of-text-grid {
            margin: 48px 0;
            display: grid;
            grid-gap: 1px;
            grid-template-columns: repeat(2, 1fr);
            max-width: 1200px;
            >.graphic {
                margin: 0;
                &.full-width {
                    grid-column-start: 1;
                    grid-column-end: -1;
                }
                >img {
                    display: block;
                }
            }
        }
        .graphic-grid {
            width: 960px;
            margin-top: 1px;
            display: grid;
            grid-gap: 1px;
            grid-template-columns: repeat(2, 1fr);
            >.graphic {
                margin: 0;
                &.full-width {
                    grid-column-start: 1;
                    grid-column-end: -1;
                }
                >img {
                    display: block;
                }
            }
            &.complex {
                grid-template-columns: repeat(3, 1fr);
                >.graphic {
                    margin: 0;
                    &.full-width {
                        grid-column-start: 1;
                        grid-column-end: -1;
                    }
                    >img {
                        display: block;
                    }
                    &:nth-of-type(2) {
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }
                    &:nth-of-type(3) {
                        grid-column-start: 2;
                        grid-column-end: 4;
                        grid-row-end: span 2;
                    }
                    &:nth-of-type(4) {
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }
                    &:nth-of-type(5) {
                        grid-column-start: 1;
                        grid-column-end: 3;
                        grid-row-end: span 2;
                    }
                    &.full-height {
                        grid-row-end: span 2;
                    }
                    &:nth-of-type(9) {
                        grid-column-start: 2;
                        grid-column-end: 4;
                        grid-row-end: span 2;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .content.journal {
        >article {
            // Convert wide format photos to 960 base width
            .graphic.px1200 {
                width: 960px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .content.journal {
        >article {
            padding: 12% 12% 0;
            .text {
                line-height: 25px;
                >p {
                    margin: 10px 0;
                }
                >p + p {
                    text-indent: 15px;
                }
            }
            .graphic + .text {
                margin-top: 20px;
            }
            .quip {
                margin-left: 0;
            }
            .graphic {
                width: 100%;
                width: auto !important;
                margin: 10px 0 !important;
                >img {
                    width: 100%;
                    max-width: 100%;
                }
            }
            .graphic-grid {
                width: auto;
                display: block;
                >.graphic {
                    margin: 0 0 1px 0 !important;
                }
                &.complex {
                    display: block;
                    >.graphic {
                        margin-bottom: 1px;
                    }
                }
            }
            .source {
                .table {
                    >.row {
                        >.cell {
                            &.label {
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}